 /**
 * Card feature helpers
 *
 * @module cards
 * @since 03.30.17
 * @author Matt Smiley <matt@contextualcode.com>
 * @group Contextual Code
 * @license MIT
 */

(function ($) {
    JS.init(function () {

        // add unique identifiers to each card on the page
        $('.card-item').each(function(i){
            $(this).addClass('card-number-' + i);
        });

        // move each into its new home
        $(".card-number-0 .card-readmore").appendTo(".card-number-0 .card-content .ezrichtext-field p");
        $(".card-number-1 .card-readmore").appendTo(".card-number-1 .card-content .ezrichtext-field p");
        $(".card-number-2 .card-readmore").appendTo(".card-number-2 .card-content .ezrichtext-field p");

    });
})(jQuery);