$(document).ready(function() {
    // look for xlsx file extensions and consider them xls
    $('a[href$=".xlsx"], a[href$=".pptx"]').addClass('file-xls');

    // look for other commom mimetypes and add the dowload icon
    $('a[href$=".au"]', 'a[href$=".au"]', 'a[href$=".avi"]', 'a[href$=".bmp"]', 'a[href$=".bz2"]','a[href$=".dtd"]', 'a[href$=".doc"]', 'a[href$=".exe"]', 'a[href$=".gz"]', 'a[href$=".hqx"]', 'a[href$=".jar"]', 'a[href$=".midi"]', 'a[href$=".mp3"]', 'a[href$=".mpeg"]', 'a[href$=".ogg"]', 'a[href$=".pl"]', 'a[href$=".ppt"]', 'a[href$=".ps"]', 'a[href$=".qt"]', 'a[href$=".ra"]', 'a[href$=".ram"]', 'a[href$=".rdf"]', 'a[href$=".rtf"]', 'a[href$=".sgml"]', 'a[href$=".sit"]', 'a[href$=".swf"]', 'a[href$=".tar.gz"]', 'a[href$=".tgz"]', 'a[href$=".tsv"]', 'a[href$=".txt"]', 'a[href$=".wav"]', 'a[href$=".zip"]', 'a[href$=".docx"]', 'a[href$=".ppt"]', 'a[href$=".pptx"]', 'a[href$=".dot"]', 'a[href$=".dotx"]', 'a[href$=".xlt"]', 'a[href$=".xltx"]', 'a[href$=".pot"]', 'a[href$=".potx"]', 'a[href$=".pps"]', 'a[href$=".ppsx"]', 'a[href$=".pub"]').addClass('file-download');

    // add nav inidicator arrow
    $('<img class="nav-arrow" alt="navigation selection" src="/assets/build/images/nav-selection.png">').insertBefore('.sidemenu .panel-body .menu.nav li.current > a');

    // remove empty paragraph tags in content boxes
    $('.panel-body .ezrichtext-field > p:empty').remove();

});