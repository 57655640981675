

 /**
 * Scotch Panel Config
 *
 * @since 07.30.20
 * @author Matt Smiley <matt@contextualcode.com>
 * @group Contextual Code
 * @license MIT
 */


 /* hiding this for now
var scotchPanel = null;
var initScotch = function(scotchPanel) {
    // main scotch menu
    var scotchPanel = $('#scotch-panel').scotchPanel({
        containerSelector: '#scotch-panel-menu-container',
        direction: 'right',
        duration: 300,
        transition: 'ease',
        clickSelector: '.toggle-panel',
        distanceX: '50%',
        enableEscapeKey: true,
        beforePanelOpen: function() {

        },
        afterPanelClose: function() {
            closeAll();
        }
    });
    return scotchPanel;
};

$(window).on('load resize', function () {
    if ($(window).width() < 992) {
        initScotch();
    }
});
*/

// main scotch menu
var scotchPanel = $('#scotch-panel').scotchPanel({
  containerSelector: '#scotch-panel-menu-container',
  direction: 'right',
  duration: 300,
  transition: 'ease',
  clickSelector: '.toggle-panel',
  distanceX: '50%',
  enableEscapeKey: true,
  beforePanelOpen: function() {

  },
  afterPanelClose: function() {
      closeAll();
  }
});

// find all the potential triggers for submenus
var potentials = document.querySelectorAll("[class*=um-menu-]");
var panels = []; // array of the panels we're creating below
elementLoop:
for (var i = 0; i < potentials.length; i++) {
  var potential = potentials[i];
  classLoop:
  // for each potential trigger
  for (var j = 0; j < potential.classList.length; j++) {
      // if we have a match
      if (potential.classList[j].match(/^um-menu-/)) {

          // set up some variables
          var toggleMenu = $('.' + potential.classList[j]);
          var toggleSelector = $(toggleMenu).prev();
          var toggleClass = ('toggle-' + potential.classList[j]);

          // add the correct class to the trigger element
          $(toggleSelector).addClass(toggleClass);
          // give it a data-attribute showing which menu it pairs with
          $(toggleSelector).attr('data-toggle-menu', potential.classList[j]);
          // add a data attribute to the menu itself showing which trigger it pairs with
          $(toggleMenu).attr('data-toggle-class', toggleClass);
          // move all the menu panels outside of their list item (for formatting)
          $(toggleMenu).insertBefore('.first-level-nav');
          // create a new panel per each discovered submenu/trigger pair
          var currentPanel = $(toggleMenu).scotchPanel({
              containerSelector: '#scotch-panel',
              clickSelector: '.' + toggleClass,
              direction: 'right',
              duration: 300,
              transition: 'ease',
              distanceX: '100%',
              enableEscapeKey: true,
              afterPanelOpen: function() {
                  // associate the close button with the current menu
                  $('.close-this-panel').click(function() {
                      currentPanel.close();
                      $('.inside-panel').each(function() {
                          $(this).removeClass('is-active');
                          $(this).addClass('is-inactive');
                      });
                  });
              }
          });
          // push the new panel to the panels array
          panels.push(currentPanel);
          // continue loop
          continue elementLoop;
      }
  }
}

// show panel passed to this function
var showMenu = function(elem) {
  if ($(elem).hasClass('is-inactive')) {
      $(elem).addClass('is-active');
      $(elem).removeClass('is-inactive');
  } else {
      $(elem).addClass('is-active');
  }
}

// close the main scotch panel
var closeAll = function() {
  if (typeof scotchPanel !== 'undefined') {
      scotchPanel.close();
  } else {
      console.log('scotchPanel is undefined');
  }
};

// button to close main scotch panel
$('.close-all-panels').on('click', function () {
  closeAll();
});

// when we click on a trigger, show the associated submenu panel
$('.toggle-um').on('click', function () {
  var requestedMenu = $('.' + $(this).data('toggle-menu'));
  showMenu(requestedMenu);
});

// close main scotch panel if the window is larger than 992px width
$(window).on('load resize', function () {
  if ($(window).width() >= 992 && $('.scotch-panel-canvas').hasClass('scotch-is-showing')) {
      closeAll();
  }
});

// if we click on the overlay close all the scotch panels
$('.overlay').on('click', function () {
  closeAll();
});

// a11y scotch canvas needs aria roles
$('.scotch-panel-canvas').attr('role', 'main').attr('aria-label', 'main page area');


// Init helper (workaround for #1qtc2w6)
var selectText = function(node) {
  node = document.getElementById(node);

  if (document.body.createTextRange) {
      const range = document.body.createTextRange();
      range.moveToElementText(node);
      range.select();
  } else if (window.getSelection) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(node);
      selection.removeAllRanges();
      selection.addRange(range);
  } else {
      console.warn("Could not select text in node: Unsupported browser.");
  }
}
var initHelper = function() {
  selectText('menu-helper');
};
$(window).on('load', function () {
  setTimeout(initHelper, 50);
});