function _mlaAcceptDonateBanner(e) {
    // set cookie to hide donate banner for one year
    var date = new Date();
    date.setFullYear( date.getFullYear() + 1 );
    document.cookie = "hide_donate_banner=1; expires=" + date + ";";
}

function _mlaDismissDonateBanner(e) {
    e.preventDefault();
    // set cookie to hide donate banner for one week
    var date = new Date();
    date.setDate( date.getDate() + 7 );
    document.cookie = "hide_donate_banner=1; expires=" + date + ";";
    // hide
    jQuery(e.target).parents(".donate-banner").addClass("donate-banner-hide");
}

// wait for hinclude
$("#donate-banner-hinclude").children().first().on("hinclude", function(e) {
    // hide if cookie is found
    if (document.cookie.indexOf("hide_donate_banner") != -1) {
        $(this).css({"display" : "none"});
        return;
    }

    // recolor banner if needed
    $(this).find(".donate-banner_grey").css({"background-color": "rgba(177,178,179,0.9)"});

    $(this).find("a.btn").click(_mlaAcceptDonateBanner);
    $(this).find(".dismiss, a[href='#']").click(_mlaDismissDonateBanner);

});


