var searchResultElement = $("#search-results");
var searchFacetsElement = $("#search-facets");
var searchFormElement = $("#search-main-form");
var loadingSpinners = $("#search-page .is-loading");
var searchSortInputElement = $('#search-sort');
var searchSortDropdownOptionElementsSelector = '.search-sort a';
var facetUpdateTimeout = null;

function getInputValFromData(data) {
    var dataVar, dataVars = data.split('&');
    for (var i = 0; i < dataVars.length; i++) {
        dataVar = dataVars[i].split('=');
        if (dataVar[0] === 'q') {
            return decodeURIComponent(dataVar[1]);
        }
    }
}
function performSearch(url, data, fromState)
{
    window.scrollTo(0,0);
    if (fromState) {
        searchFormElement.find("input[name=q]").val(getInputValFromData(data));
    } else {
        window.history.pushState({'data' : data}, "", '?' + data);
    }
    // reset elements
    loadingSpinners.show();
    searchResultElement.addClass("is-loading");
    searchFacetsElement.addClass("is-loading");
    searchFormElement.find("input").prop("disabled", true);
    // ajax
    $.ajax(
        url,
        {
            data: data,
            success: function(res) {
                // set elements, remove loading status
                loadingSpinners.hide();
                searchResultElement.removeClass("is-loading");
                searchFacetsElement.removeClass("is-loading");
                searchFormElement.find("input").prop("disabled", false);
                searchResultElement.find("a").prop("disabled", true);
                searchResultElement.html(
                    res.results_html
                );
                searchFacetsElement.html(
                    res.facet_html
                );
                searchResultElement.find(".pagination a").click(function(e) {
                    e.preventDefault();
                    if ($(this).prop("disabled") || !$(this).attr("href")) {
                        return false;
                    }
                    performSearch(
                        searchFormElement.attr("action"),
                        $(this).attr("href").slice(1)
                    );
                    return false;
                });
                searchFacetsElement.find(".checkbox label").click(function(e) {
                    if ($(this).prop("disabled")) {
                        return false;
                    }
                    var checkbox = $(e.target).prev();
                    checkbox.prop('checked', !checkbox.prop('checked'));
                    clearTimeout(facetUpdateTimeout);
                    facetUpdateTimeout = setTimeout(function() {
                        performSearch(
                            searchFormElement.attr("action"),
                            searchFormElement.serialize() + '&' + searchFacetsElement.find('input').serialize()
                        );
                    }, 500);
                });
            }
        }
    ).fail(function(jqXHR, textStatus, errorThrown) {
        console.log("ERROR: ", textStatus, errorThrown);
        searchResultElement.removeClass("is-loading");
        searchFacetsElement.removeClass("is-loading");
        searchResultElement.addClass("is-error");
        searchFacetsElement.addClass("is-error");
        searchResultElement.html("There has been an unexpected error.");
    });
}

if (searchFormElement.length) {
    searchFormElement.on("submit", function(e) {
        e.preventDefault();
        performSearch(
            $(this).attr("action"),
            $(this).serialize() + '&' + searchFacetsElement.find('input').serialize()
        );
        return false;
    });

    $(document).on("click", searchSortDropdownOptionElementsSelector, function(e) {
        e.preventDefault();
        var newSort = $(e.target).data('sort');
        searchSortInputElement.val(newSort);
        searchFormElement.trigger('submit');
    });

    $(document).ready(function () {
        performSearch(
            searchFormElement.attr("action"),
            window.location.search.slice(1),
            true
        );
    });

    $(window).on("popstate", function (e) {
        performSearch(
            searchFormElement.attr("action"),
            window.location.search.slice(1),
            true
        );
    });
}