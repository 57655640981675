// Banner Image Credit Toggle
$(document).ready(function() {
    $(".carousel .item .image-info").click(function() {
        if ($(this).hasClass("open")) {
            $(this).removeClass("open");
        } else {
            $(this).addClass("open");
        }
    });
});
