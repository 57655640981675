+function( global, $ ) {


    function CacheManager() {
        var Cache = {};

        this.add = function( key, value ) {
            return Cache[ key ] = value;
        }

        this.get = function( key ) {
            if( this.has( key ) ) {
                return Cache[ key ];
            }
        }

        this.has = function( key ) {
            return (
                typeof Cache[ key ] !== "undefined"
            );
        }
    }


    var
        TemplateCache = new CacheManager(),
        TemplateHandler = {
            "addTemplate" : function( name, template ) {
                TemplateCache.add(
                    name,
                    {
                        "Name" : name,
                        "Template" : template,
                        "isCompiled" : false
                    }
                );
            },
            "compile" : function( template_name ) {
                if(
                    TemplateCache.has( template_name )
                ) {
                    var
                        Closure = null,
                        Reference = TemplateCache.get( template_name )
                    ;
                    if( !Reference.isCompiled ) {
                        if(
                            typeof( Closure = Handlebars.compile( Reference.Template, {"noEscape":true} ) ) !== "function"
                        ) {
                            return false;
                        }
                        Reference.isCompiled = true;
                        Reference.Template = Closure;
                    }
                    return Reference.Template;
                }
            },
            "render" : function( template_name, variables ) {
                var Closure = this.compile( template_name );
                if( Closure ) {
                    return Closure( variables );
                }
            }
        },


        RequestCache = new CacheManager(),
        RequestHandler = {
            "load" : function( url ) {
                if(
                    !RequestCache.has( url )
                ) {
                    var Response = $.ajax(
                        { "url" : url }
                    );

                    return Response.then(
                        function( data ) {
                            return RequestCache.add( url, Response );
                        }
                    );
                }

                return RequestCache.get( url );
            }
        }
    ;


    function onBulletinAccordionClick( e ) {
        var
            _this = $( this ),
            _configuration = _this.data(),
            Response = null,
            ResponseContent = _this.find( _configuration.bulletinAccordion )
        ;
        e.stopPropagation();

        if( _configuration.bulletinAccordionRequest ) {
            _this.addClass( "loading" );
            Response = RequestHandler.load( _configuration.bulletinAccordionRequest );
        }

        $.when( Response ).then(
            function( data ) {
                if(
                    !ResponseContent.length && data && _configuration.bulletinAccordionTemplate
                ) {
                    _this.append(
                        TemplateHandler.render(
                            _configuration.bulletinAccordionTemplate, data
                        )
                    );
                    ResponseContent = _this.find( _configuration.bulletinAccordion );
                }

                ResponseContent.slideToggle();
                _this.toggleClass( "active" );
                _this.removeClass( "loading" );
            }
        );
    }

    global.BulletinManager = {

        "initializeBulletinIssueList" : function( configuration ) {
            if(
                typeof configuration["selector"] !== "undefined"
            ) {
                var BulletinAccordionList = $( configuration["selector"] );

                BulletinAccordionList.on( "click", onBulletinAccordionClick );

                if( configuration["allow_ignore"] ) {
                    BulletinAccordionList.on(
                        "click", ".bulletin-accordion-ignore", function( e ) { e.stopPropagation(); }
                    );
                }

                $.each(
                    configuration["template_list"], TemplateHandler.addTemplate
                );
            }
        },

    };

}( window, jQuery );
