// Bookstore breadcrumbs

// /shop/bookstore/tag/112/ (Subjects)
// /shop/bookstore/tag/113/ (Periods)
// ^these two are special cases

var loc = window.location.pathname;

var bc112 = "<ol class='breadcrumb bc-js'>"+
    "<li class='location-id-88 current'>"+
        "<a href='/'>Home</a>"+
    "</li>"+
    "<li class='location-id-10112'>"+
        "<a href='/Publications'>Publications</a>"+
    "</li>"+
    "<li class='location-id-14710'>"+
        "<a href='/Publications/Bookstore'>Bookstore</a>"+
    "</li>"+
    "<li class='active'>Browse Subjects</li>"+
"</ol>";

var bc113 = "<ol class='breadcrumb bc-js'>"+
    "<li class='location-id-88 current'>"+
        "<a href='/'>Home</a>"+
    "</li>"+
    "<li class='location-id-10112'>"+
        "<a href='/Publications'>Publications</a>"+
    "</li>"+
    "<li class='location-id-14710'>"+
        "<a href='/Publications/Bookstore'>Bookstore</a>"+
    "</li>"+
    "<li class='active'>Browse Periods</li>"+
"</ol>";

var addBreadcrumb = function(bc) {
    $("#breadcrumb").html(bc);
}

if (loc === "/shop/bookstore/tag/112/") {
    addBreadcrumb(bc112);
}

if (loc === "/shop/bookstore/tag/113/") {
    addBreadcrumb(bc113);
}