// tabs
if (typeof(jQuery) != "undefined") {
    function myMlaNavigateTab()
    {
        var hash = window.location.hash.substring(1);
        hash && jQuery('.nav-tabs a[href="#_' + hash + '"]').tab('show');
    }
    myMlaNavigateTab();
    jQuery(window).bind("popstate", function(evt) {
        myMlaNavigateTab();
    });
    jQuery(".nav-tabs a").on("shown.bs.tab", function() {
        footerPosition();
        var tabText = jQuery(this).attr("href");
        if (tabText.includes("#_")) {
            window.location.hash = tabText.substring(2);
        } else {
            window.location.hash = tabText.substring(1);
        }
    });
    jQuery("a[data-toggle=popover]").popover();
}

// form modal
var MY_MLA_FORM_URL = "/user/edit";
var MY_MLA_MODAL_ID = "my-mla-edit-form";
function myMlaOpenForm(formName)
{
    var modalElement = jQuery("#" + MY_MLA_MODAL_ID);
    jQuery("html").css({"overflow-y" : "hidden"});
    modalElement.removeClass("hide");
    modalElement.find(".loading").show();
    modalElement.find(".error").addClass("hide");
    modalElement.find("iframe")
        .hide()
        .attr("src", MY_MLA_FORM_URL + "/" + formName)
        .unbind("load")
        .on("load", function() {
            modalElement.find(".loading").hide();
            var errorRef = jQuery(this).contents().find(".error-reference");
            var mlaFormElement = jQuery(this).contents().find(".mla-form")
            if (errorRef.length > 0 || mlaFormElement.length == 0) {
                jQuery(this).hide()
                modalElement.find(".error").removeClass("hide");
                if (errorRef.length > 0) {
                    modalElement.find(".error .error-reference").html(errorRef.html());
                }
                return;
            }
            jQuery(this).show();
        })
    ;
}
function myMlaCloseForm()
{
    var modalElement = jQuery("#" + MY_MLA_MODAL_ID);
    jQuery("html").css({"overflow-y" : ""});
    modalElement.addClass("hide");
}
function myMlaHookFormButtons()
{
    jQuery("a[data-member-edit]")
        .removeAttr("disabled")
        .unbind("click")
        .on("click", function(event) {
            event.stopPropagation();
            event.preventDefault();
            myMlaOpenForm(
                jQuery(this).attr("data-member-edit")
            );
            return false;
        })
    ;
}

if (typeof(jQuery) != "undefined") {
    jQuery(document).ready(function() {
        myMlaHookFormButtons();
        jQuery(window).on("resize", myMlaHookFormButtons);
    });
}

// reload user data
function myMlaReloadData(element)
{
    var e = element;
    if (!jQuery(e).attr("data-member-read")) {
        return;
    }
    jQuery(e).addClass("loading");
    jQuery.get(
        "/user/read/" + jQuery(e).attr("data-member-read"),
        "",
        function(data) {
            jQuery(e).removeClass("loading");
            jQuery(e).html(data);
        },
        "html"
    );
}

// embeddded form (career path, forums)
function myMlaEmbedFormResize(formName)
{
    setInterval(myMlaEmbedFormResizeInterval, 1000, formName);
    myMlaFormIframeHookSubmit();
}

function myMlaEmbedFormResizeInterval(formName)
{
    var iframeElement = jQuery("iframe.embedded-form[data-form=" + formName + "]");
    if (iframeElement.length == 0) { return; }
    var currentHeight = parseInt(jQuery(iframeElement).height());
    if (!iframeElement[0] || !iframeElement[0].contentWindow || !iframeElement[0].contentWindow.document) { return; }
    var newHeight = iframeElement[0].contentWindow.document.body.scrollHeight;
    if (!newHeight || currentHeight == newHeight) { return; }
    jQuery(iframeElement).css({"height" : (newHeight) + "px"});
}

// hook iframe form submit buttons
function myMlaFormIframeHookSubmit()
{
    jQuery("iframe.embedded-form[data-form]:visible").each(function() {
        var submitButton = jQuery(this).attr("data-submit-button");
        var t = this;
        if (submitButton) {
            jQuery(submitButton)
                .removeClass("loading")
                .removeClass("success")
                .unbind("click")
                .on("click", function(event) {
                    event.stopPropagation();
                    event.preventDefault();
                    jQuery(this).unbind("click");
                    jQuery(this).addClass("loading");
                    jQuery(t).contents().find("form").submit();
                    return false;
                })
            ;

            // success
            if (jQuery(this).contents().find(".mla-form-success").length > 0) {
                jQuery(submitButton).addClass("success");
                setTimeout(
                    function() {
                        jQuery(submitButton)
                            .removeClass("success")
                        ;
                    },
                    3000
                );
            }

        }
    });
}

// load contents for iframes when needed
function myMlaInitFormIframes()
{
    jQuery("iframe.embedded-form[data-form]:visible").not(".init").each(function() {
        var formName = jQuery(this).attr("data-form");
        jQuery(this)
            .attr("src", MY_MLA_FORM_URL + "/" + formName + "?no_success_redirect=1&no_modal=1")
            .addClass("init")
        ;
        jQuery(this).on("load", function(event) {
            jQuery(this).css({"background" : "none"});
            jQuery(this).contents().find("h2").first().remove();
            var t = this;
            myMlaEmbedFormResize(formName);
        });
    });
}

if (typeof(jQuery) != "undefined") {
    jQuery(document).ready(function() {
        myMlaInitFormIframes();
        myMlaFormIframeHookSubmit();
    });
    jQuery(".nav-tabs a").on("shown.bs.tab", function() {
        myMlaInitFormIframes();
        myMlaFormIframeHookSubmit();
    });
    jQuery("a.arb-cancel").click(function(e) {
        e.preventDefault();
        var target = jQuery(this).attr("data-target");
        bootbox.confirm(
            "Are you sure you want to cancel automatic renewal?",
            function(res) {
                if (res) {
                    window.location.href = target;
                }
            }
        );
    });
}

// Set footer CSS so that it's flush bottom
// of the page
function footerPosition()
{
    // reset footer css
    $("footer").css({ "width" : "", "position" : "", "bottom" : "" });

    // measure height of page
    pageHeight = $("body").height();

    // get window height
    windowHeight = $(window).height();

    // if page height is less then window height use css
    // to position footer at bottom of page
    if (pageHeight < windowHeight) {
        $("footer").css({
            "width" : "100%",
            "position" : "absolute",
            "bottom" : "0px"
        });
    }
}

// Update footer position every second to account
// for DOM changes
var footerPosUpdate;
function footerPosInterval()
{
    clearTimeout(footerPosUpdate);
    footerPosition();
    footerPosUpdate = setTimeout(
        function() {
            footerPosInterval()
        },
        1000
    );
}

if (typeof(jQuery) != "undefined") {
    footerPosition();
    jQuery(window).resize(function() {
        footerPosition();
    })
    jQuery(window).load(function() {
        footerPosition();
    })

    footerPosInterval();
}