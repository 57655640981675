/*
 * HandlebarsJS extenders component
 */
+function( global, $, handlebars ) {

    var
        Helpers = {

            /*
             * https://gist.github.com/pheuter/3515945
                http://doginthehat.com.au/2012/02/comparison-block-helper-for-handlebars-templates/
                    http://doginthehat.com.au/2012/02/comparison-block-helper-for-handlebars-templates/#comment-44
             */
            "compare" : function( lvalue, operator, rvalue, options ) {
                if( arguments.length < 3 ) {
                    throw new Error( "Handlerbars Helper 'compare' needs 2 parameters" );
                }

                if( options === undefined ) {
                    options = rvalue;
                    rvalue = operator;
                    operator = "===";
                }

                var
                    Operators = {
                        "==": function( l, r ) { return l == r; },
                        "===": function( l, r ) { return l === r; },
                        "!=": function( l, r ) { return l != r; },
                        "!==": function( l, r ) { return l !== r; },
                        "<": function( l, r ) { return l < r; },
                        ">": function( l, r ) { return l > r; },
                        "<=": function( l, r ) { return l <= r; },
                        ">=": function( l, r ) { return l >= r; },
                        "typeof": function( l, r ) { return typeof l == r; }
                    }
                ;

                if( !Operators[operator] ) {
                    throw new Error( "Handlerbars Helper 'compare' doesn't know the operator " + operator );
                }

                if(
                    Operators[operator]( lvalue, rvalue )
                ) {
                    return options.fn( this );
                }

                return options.inverse( this );
            }

        }
    ;

    $.each(
        Helpers, function( key, value ) { handlebars.registerHelper( key, value ); }
    );

}( window, jQuery, Handlebars );
