+function( global, $ ) {

    function processUserLoginForm( form, context ) {
        if( context.name == "_username" || context.name == "_password" ) {
            var LoginFieldName = (
                context.name == "_username" ? "username" : "password"
            );

            form.find( "[name=" + LoginFieldName + "]" ).val( context.value );
        }
    }

    function onLoginFormSubmitEvent( e ) {
        var _this = $( this );

        if(
            !_this.data( "allow" )
        ) {
            var
                _iframe = e.data.iframe,
                DualLoginForm = _iframe.contents().find( "#double-login-form" )
                SubmitTimeout = null
            ;

            _this.find( "[type=submit]" ).prop( "disabled", true );

            _iframe.on(
                "load", function() { clearTimeout(SubmitTimeout); _this.data( "allow", true ); _this.submit(); }
            );

            SubmitTimeout = setTimeout(
                function() {
                    _this.data( "allow", true ); _this.submit();
                },
                3000
            );

            $.each(
                _this.serializeArray(), function() { processUserLoginForm( DualLoginForm, this ); }
            );

            DualLoginForm.submit();

            return _this.data( "allow" );
        }
    }

    $(
        function() {

            var EventData = {
                "iframe" : $( '<iframe src="/double/form" width="0" height="0" style="display:none;"></iframe>' ).appendTo( "body" )
            };

            $( "#nav-login-dropdown form, .mla-form.form-login form, .mla-form.form-login2 form" )
                .data( "allow", false )
                .on(
                    "submit", EventData, onLoginFormSubmitEvent
                )
            ;

        }
    );

}( window, jQuery );
