 /**
 * Helper to reorder the join block and the email signup form on the homepage
 *
 * @module homepage-signup
 * @since 03.28.17
 * @author Matt Smiley <matt@contextualcode.com>
 * @group Contextual Code
 * @license MIT
 */

(function ($) {
    JS.init(function () {

        // machforms is evil so let's try and get it to make some sense for us
        $('#homepage-banner-signup .form_description').addClass('email-signup-label col-xs-12 push-xs-12 col-sm-8 col-md-5');
        $('#homepage-banner-signup #form_container .appnitro ul').addClass('email-signup-form col-xs-12 push-xs-12 col-sm-8 col-md-5');
        $('<b>Subscribe to the free MLA News Digest.</b>').insertAfter('#homepage-banner-signup #form_container .appnitro .email-signup-label p:first-of-type');
        $('#homepage-banner-signup .email-signup-form button').addClass('email-signup-button').text('Sign me up!');
        $('#homepage-banner-signup #element_1').attr('value', ''); // clear the bad value in the input so the error shows

        // add placolder text
        $('.email-signup-form input#element_1').each(function(i,el) {
            if (!el.value || el.value == '') {
                el.placeholder = 'E-mail address';
            }
        });

        // make value of submit button '1'
        $('button#saveForm').val('1');

        // if an error exists
        if( $('#homepage-banner-signup p.error').length ){
            // clear placeholder text
            $('.email-signup-form input#element_1').each(function(i,el) {
                if (!el.value || el.value == '') {
                    el.placeholder = ' ';
                }
            });
        }
        if ($('.email-signup-form input#element_1').is(":focus")) {

            $('.email-signup-form .error').css('display', 'none');
            // then replace the placeholder text
            $('.email-signup-form input#element_1').each(function(i,el) {
                if (!el.value || el.value == '') {
                    el.placeholder = 'E-mail address';
                }
            });
        }

        $(document).on('click', '.email-signup-form', function(){
            $('.email-signup-form li.error > p').addClass('dismissed');
        });

    });
})(jQuery);