$(document).ready(function() {
    $(".readmore").each(function() {
        var self = this;
        $(self).find(".read-more-link").click(function() {
            if ($(self).hasClass("open")) {
                $(self).removeClass("open");
                $(self).find(".read-more-content").height( 0 );
            } else {
                $(self).addClass("open");
                $(self).find(".read-more-content").height( $(self).find(".read-more-inner-content").height() + 15 );
            }
            return false;
        });
        $(self).find(".read-less-link").click(function() {
            $(self).removeClass("open");
            $(self).find(".read-more-content").height( 0 );
            return false;
        });
    });
    $(window).resize(function() {
        $(".readmore.open").each(function() {
            $(this).find(".read-more-content").height( $(this).find(".read-more-inner-content").height() + 15 );
        });
    });

    // remove empty paragraph tags
    $(".read-more-inner-content p").each(function() {

        if($.trim($(this).html()).length > 0) {
            $(this).hide();
        }

    });

});