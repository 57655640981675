 /**
 * Login container - sometimes we want one, sometimes we don't
 *
 * @module login-container
 * @since 08.19.20
 * @author Matt Smiley <matt@contextualcode.com>
 * @group Contextual Code
 * @license MIT
 */

 (function ($) {
    JS.init(function () {

/*
There is a container around the login form that can cause issues when used in a column layout.
    https://mla.org/Membership/MLA-Newsletter/Current-Newsletter
    vs. 
    https://mla.org/use/login
*/

        if ($('.main-content #right-column .member-only-content').length) {
            $('#right-column .member-only-content > .container').removeClass('container');
        }

    });
})(jQuery);