

// "/user" Membership History Table
$('#main-content .table.member-history-table').cardtable();

// Object States Customtag Tables
$('#main-content .tc-objectstate-list .table').stacktable();

// Custom Content Tables
$('#main-content .ezrichtext-field .cardtables').cardtable();
$('#main-content .ezrichtext-field .stackedtable').stacktable();
$('#main-content .ezrichtext-field .stackcols').stackcolumns();