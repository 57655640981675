
// desktop menu
$("#global-navigation > li:not(.no-dropdown)").click(function() {
    // Lets toggle the menu dropdowns always
    $(this).find('> a').attr("data-toggle", "dropdown").dropdown();
    // then focus on the first link so you can tab through
    $(this).find('.dropdown-menu h2 a').focus();
});

// 'MLA Style' menu should display with only two columns
$('.location-id-10111 .dropdown-menu .columnize').addClass('two-column-layout');

// 'Publications' menu should display with only three columns
$('.location-id-10112 .dropdown-menu .columnize').addClass('three-column-layout');

// 'Resource' menu should display with only three columns
$('.location-id-10113 .dropdown-menu .columnize').addClass('three-column-layout');
