// ThinkCreative Calendar Main JS

// updates calendar view, triggered on form submit
function tcUpdateCalendarView(calendar_id, form_data) {

    var calendar_id = calendar_id;
    var calendar_element = $("#tc-calendar-" + calendar_id);

    // clear tc-view
    calendar_element.find(".tc-view")
        .html("")
        .addClass("throber")
    ;

    // perform ajax request for more data
    $.ajax({
        url: "/tc-calendar/" + calendar_element.attr("data-location-id") + ".json",
        data: form_data,
        success: function(results) {

            // update content
            calendar_element.find(".tc-view")
                .html(results.output)
                .removeClass("throber");
            calendar_element.find(".tc-calendar-title").html(results.view_title);

            // pagination
            calendar_element.find(".tc-calendar-prev").parent().removeClass("disabled");
            calendar_element.find(".tc-calendar-prev").attr("href", "?" + results.prev_page);
            calendar_element.find(".tc-calendar-next").parent().removeClass("disabled");
            calendar_element.find(".tc-calendar-next").attr("href", "?" + results.next_page);
            if (!results.has_next_page) {
                calendar_element.find(".tc-calendar-next").parent().addClass("disabled");
            }
            if (!results.has_prev_page) {
                calendar_element.find(".tc-calendar-prev").parent().addClass("disabled");
            }

            calendar_element.attr("data-date", results.visible_start_date);
            calendar_element.attr("data-offset", results.offset);

            // bind click event to .update-view class
            calendar_element.find(".update-view").unbind("click").click(function() {
                updateView(
                    calendar_id,
                    $(this).attr("href").substring(1)
                );
                return false;
            });
        },
        error: function(data) {
            // update content
            calendar_element.find(".tc-view")
                .html("<p><em>An error has occured.</em></p>")
                .removeClass("throber");
        },
        dataType: "json"
    });
}


$(document).ready(function() {

    // add btn class to view radio buttons
    $(".tc-calendar[data-instance-id] .view-group label").addClass("btn");
    $(".tc-calendar[data-instance-id] .view-group label").addClass("btn-default");

    // form submit event
    $(".tc-calendar[data-instance-id] form.tc-calendar-controls").submit(function() {

        data = {
            "location_id" : $(this).parents(".tc-calendar").attr("data-location-id"),
            "offset" : $(this).parents(".tc-calendar").attr("data-offset"),
            "date" : $(this).parents(".tc-calendar").attr("data-date")
        };
        tcUpdateCalendarView(
            $(this).parents(".tc-calendar").attr("data-instance-id"),
            $(this).serialize() + "&" + $.param(data)

        );
        return false;
    });

    // pagination click
    $(".tc-calendar[data-instance-id] .tc-calendar-prev, .tc-calendar[data-instance-id] .tc-calendar-next, .tc-calendar .update-view").click(function() {
        tcUpdateCalendarView(
            $(this).parents(".tc-calendar").attr("data-instance-id"),
            $(this).attr("href").substring(1)
        );
        return false;
    })

    // view button toggles
    $(".tc-calendar[data-instance-id] form.tc-calendar-controls .view-group input").change(function() {
        $(this).parents(".view-group").find("label").removeClass("active");
        $(this).parents("label").addClass("active");
    });

    // (all) checkbox toogle
    $(".tc-calendar[data-instance-id] form.tc-calendar-controls .filter-group input[type=checkbox][value=_all]").click(function(){
        $(this).prop("checked", true);
        $(this).parents(".filter-group").find("input[type=checkbox][value!=_all]").prop("checked", false);
    });
    $(".tc-calendar[data-instance-id] form.tc-calendar-controls .filter-group input[type=checkbox][value!=_all]").change(function() {
        if ($(this).parents(".filter-group").find("input[type=checkbox][value!=_all]:checked").length <= 0) {
            $(this).parents(".filter-group").find("input[type=checkbox][value=_all]").prop("checked", true);
        } else {
            $(this).parents(".filter-group").find("input[type=checkbox][value=_all]").prop("checked", false);
        }
    });

    // submit form when input is changed
    $(".tc-calendar[data-instance-id] form.tc-calendar-controls input, .tc-calendar[data-instance-id] form.tc-calendar-controls select").change(function() {
        $(this).parents("form").submit();
    });

});

// Custom Javascript for Event Calendar
function tcCalendarMultiUpdate(form_element)
{
    var form_element = $(form_element);
    $(".tc-calendar[data-instance-id]").each(function() {
        data = {
            "location_id" : $(this).attr("data-location-id"),
            "offset" : $(this).attr("data-offset"),
            "date" : $(this).attr("data-date")
        };
        tcUpdateCalendarView(
            $(this).attr("data-instance-id"),
            form_element.serialize() + "&" + $.param(data)

        );
    })
}

function tcCalendarFilterCheckboxDisplay(form_element)
{
    var checkboxes = $(form_element).find(".filter-list .checkbox input[type=checkbox]");
    var showAllCheckbox = $(form_element).find(".filter-list .checkbox input[type=checkbox][value=_all]");
    if (
        $(showAllCheckbox).is(":checked") || 
        $(form_element).find(".filter-list .checkbox input[type=checkbox]:checked").length >= $(checkboxes).length - 1
    ) {
        $(checkboxes).prop("checked", true);
        $(checkboxes).parents(".checkbox").removeClass("checked");
        $(showAllCheckbox).parents(".checkbox").addClass("checked");
        return;
    } else {
        $(showAllCheckbox).parents(".checkbox").removeClass("checked");
    }
    $(checkboxes).each(function() {
        if ($(this).is(":checked")) {
            $(this).parents(".checkbox").addClass("checked");
        } else {
            $(this).parents(".checkbox").removeClass("checked");
        }
    });    
}

$(document).ready(function() {
    $(".tc-calendar form.tc-calendar-controls input, .tc-calendar form.tc-calendar-controls select").removeAttr("disabled");
    var checkboxes = $(".tc-calendar form.tc-calendar-controls .filter-list .checkbox input[type=checkbox]");
    var checkboxAll = $(".tc-calendar form.tc-calendar-controls .filter-list .checkbox input[type=checkbox][value=_all]");
    $(checkboxes).hide();
    $(checkboxes).change(function() {
        if (
            (
                !$(this) != $(checkboxAll) && 
                !$(this).is(":checked") &&
                $(checkboxAll).is(":checked")
            ) || 
            $(this).is(":checked")
        ) {
            $(checkboxes).prop("checked", false);
            $(this).prop("checked", true);            
        } else {
            $(checkboxes).prop("checked", true);
        }

        tcCalendarFilterCheckboxDisplay(".tc-calendar form.tc-calendar-controls");
        tcCalendarMultiUpdate(
            $(this).parents("form.tc-calendar-controls")
        );
    });
    tcCalendarFilterCheckboxDisplay(".tc-calendar form.tc-calendar-controls");
    $(".tc-calendar form.tc-calendar-controls select").change(function() {
        tcCalendarMultiUpdate(
            $(this).parents("form.tc-calendar-controls")
        );
    });
});

