
/*-----------------------------------------------------------------------------------*/
/*    ACCORDION TOGGLES
/*-----------------------------------------------------------------------------------*/
$(function(){

    $("#accordion h4").eq(2).addClass("active");
    $("#accordion .accordion_content").eq(2).show();

    $("#accordion h4").click(function(){
        $(this).next(".accordion_content").slideToggle("slow")
        .siblings(".accordion_content:visible").slideUp("slow");
        $(this).toggleClass("active");
        $(this).siblings("h4").removeClass("active");
    });

});

$('.panel .panel-heading span.pull-right span').click(function(){
        $(this).parent().parent().parent().find('.panel-body.collapse').collapse('toggle');
        if($(this).hasClass('glyphicon-minus')){
            $(this).removeClass('glyphicon-minus');
            $(this).addClass('glyphicon-plus');
        }else{
            $(this).removeClass('glyphicon-plus');
            $(this).addClass('glyphicon-minus');
        }
});
