function ccSharePopupWindow(url, title, win, w, h) {
    var y = window.top.outerHeight / 2 + window.top.screenY - ( h / 2);
    var x = window.top.outerWidth / 2 + window.top.screenX - ( w / 2);
    return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+y+', left='+x);
}

function initCCShare()
{
    var ccShareButtons = document.getElementsByClassName("cc-share-item");
    for (var i = 0; i < ccShareButtons.length; i++) {
        var ccShareButton = ccShareButtons[i];
        var linkElement = null;

        for (var j = 0; j < ccShareButton.childNodes.length; j++) {
            if ( ccShareButton.childNodes[j].tagName.toLowerCase() === "a" ) {
                linkElement = ccShareButton.childNodes[j];
                break;
            }
        }

        if (linkElement) {
            linkElement.addEventListener(
                "click",
                function(e) {
                    e.preventDefault();

                    var findLink = null;
                    for (var j = 0; j < e.path.length; j++) {
                        if (e.path[j].tagName.toLowerCase() === "a") {
                            findLink = e.path[j];
                            break;
                        }
                    }
                    if (findLink) {
                        ccSharePopupWindow(findLink.href, "share-window", window, 580, 380);
                    }
                }
            );
        }
    }

}
initCCShare();
