// browser detection
// this is NOT bulletproof
var browserName;

// Opera 8.0+
if ((window.opr && opr.addons) || window.opera || (navigator.userAgent.indexOf(' OPR/') >= 0))
browserName = "opera";

// Firefox 1.0+
if (typeof InstallTrigger !== 'undefined')
browserName = "firefox";

// At least Safari 3+: "[object HTMLElementConstructor]"
if (Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0)
browserName = "safari";

// Internet Explorer 6-11
if ((/*@cc_on!@*/false) || (document.documentMode))
browserName = "ie";

// Edge 20+
if (!(document.documentMode) && window.StyleMedia)
browserName = "edge";

// Chrome 1+
if (window.chrome && window.chrome.webstore)
browserName = "chrome";

//    console.log( browserName + ' browser detected' );
$('html').addClass( browserName );

// safari will return both 'chrome' and 'safari' in its useragent string so we need to get more specific here
if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
    $('html').addClass('safari');
}