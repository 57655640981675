// On Print Request Make Sure Tab Boxes Display Correctly
// THIS IS ONY PARTIALLY FINISHED/WORKING - NEED TO MOVE DOM ELEMENTS AS IF WE WERE IN THE SMALL OR EXTRA SMALL MEDIA QUERY TO DISPLAY TAB BOXES CORRECTLY
if (window.matchMedia) {
    var mediaQueryList = window.matchMedia('print');
    mediaQueryList.addListener(function(mql) {
        if (mql.matches) {
            $('#product-description').insertAfter('#collapse-product-description .panel-body');
            $('#product-subjects').insertAfter('#collapse-product-subjects .panel-body');
        } else {

        }
    });
}