
$(document).ready(function () {

    $('.search-modal__trigger').on('click', function (e) {
        e.preventDefault();
        var modal = $('#search-modal');
        modal.modal();
        closeModal(modal);
    });

    $('.login-modal__trigger').on('click', function (e) {
        e.preventDefault();
        var modal = $('#login-modal');
        modal.modal();
        closeModal(modal);
    });

    var closeModal = function(modal) {
        $(modal).find('.modal-close').on('click', function (e) {
            e.preventDefault();
            $(this).parents(modal).modal('hide');
        });
    }

    if ($('#cvvInfo').length) {
        $('#cvvInfo').insertBefore('#donate-banner-hinclude');
    }
});