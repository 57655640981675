+function($) {

    function fetchBulletinPdf(obj)
    {
        var obj = obj;
        var offset = offset | 0;
        $.get(
            "/bulletin/issue_list/" + obj.attr("data-location-id") + "/" + obj.attr("data-year-range") + "/" + offset + "/" + (obj.attr('data-showtoc') ? '1' : '0'),
            function(data) {
                if (!data || !data.success) {
                    return;
                }

                if (obj.attr('data-showtoc')) {
                    obj.find("ul.bulletin-issue-container").append(data.html);

                    obj.addClass('shown');
                    children = obj.find('> ul li');
                    children.show('fast');

                    var selector = ".bulletin-issues-group[data-location-id=" + obj.attr("data-location-id") + "][data-year-range='" + obj.attr("data-year-range") + "'] .bulletin-issue[data-bulletin-accordion]:not(.loaded)";

                    $(selector)
                        .data(
                            "bulletin-accordion-template", "articles_list"
                        )
                        .hide()
                        .slideDown()
                    ;

                    BulletinManager.initializeBulletinIssueList(
                        {
                            "allow_ignore" : true,
                            "selector" : selector,
                            "template_list" : {
                                "articles_list" : '<div class="article-list">{{#each article_list}}{{#if article_category }}<div class="row"><div class="col col-xs-12"><span class="category-level-{{level}}">{{article_category}}</span></div></div>{{/if}}<div class="row"><div class="page-number col col-xs-1">{{#compare page_number "!=" 999 }}{{#compare page_number "!=" 0 }}{{page_number}}{{/compare}}{{/compare}}</div><div class="col col-xs-11"><a class="bulletin-accordion-ignore" href="{{url}}">{{{name}}}</a></div></div>{{/each}}</div>'
                            }
                        }
                    );
                    
                    $(selector).addClass("loaded");

                    // fetch more
                    if (obj.find(".bulletin-issue").length < data.count) {
                        //fetchBulletinPdf(obj, obj.find(".bulletin-issue").length);
                        return;
                    }
                    obj.removeClass('loading');
                }
                else {
                    var typeCol = ['adfl', 'ade'];
                    for (var type in data.items) {
                        typeCol = typeCol.filter(function(value) {
                            return value !== type;
                        });
                        var typeEle = obj.find(".type-" + type);
                        for (var key in data.items[type]) {
                            var item = data.items[type][key];
                            var link = document.createElement("a");
                            link.href = item.path;
                            link.innerText = item.name;
                            var h3 = document.createElement("h3");
                            h3.append(link);
                            var li = document.createElement("li");
                            li.append(h3);
                            li.setAttribute("data-location-id", item.location_id);
                            li.classList.add(type);
                            typeEle.append(li);
                        }
                    }

                    typeCol.forEach(function(unused) {
                        var typeEleUnused = obj.find(".type-" + unused);
                        typeEleUnused.hide();
                    });

                    obj.addClass('shown');
                    children = obj.find('> ul li');
                    children.show('fast');

                    var selector = ".bulletin-issues-group[data-location-id=" + obj.attr("data-location-id") + "][data-year-range='" + obj.attr("data-year-range") + "'] .bulletin-issue[data-bulletin-accordion]:not(.loaded)";

                    $(selector)
                        .data(
                            "bulletin-accordion-template", "articles_list"
                        )
                        .hide()
                        .slideDown()
                    ;

                    $(selector).addClass("loaded");

                    obj.removeClass('loading');   
                }            

            },
            "json"
        )
            .fail(function() {
                obj.find("ul").replaceWith("<li>An error has occured.</li>");
                obj.removeClass('loading')
                obj.addClass('shown');
                obj.addClass('error');
            })
        ;
    }

    function bindTreeEvent(e) {
        var
            obj = $(this),
            children = obj.find('> ul li')
        ;
        if( children.length ) {
            if(
                children.is(":visible")
            ) {
                obj.removeClass('shown');
                children.hide('fast');
            } else {
                obj.addClass('shown');
                children.show('fast');
            }

        // special case for jilpdf
        } else if ( obj.hasClass("jilpdf-year") && !obj.hasClass("loading") ) {
            obj.addClass('loading')
            $.get(
                "/jilpdf/" + obj.attr("data-location-id") + "/" + obj.attr("data-year") + "/",
                function(data) {
                    obj.find("ul").replaceWith(data);
                    obj.removeClass('loading')
                    obj.addClass('shown');
                    children = obj.find('> ul > li');
                    children.show('fast');
                }
            );
        
        // special case for bulletin issue list
        } else if ( obj.hasClass("bulletin-issues-group") && !obj.hasClass("loading") ) {
            obj.addClass('loading');
            fetchBulletinPdf(obj);
        }
        e.stopPropagation();
    }

    // http://stackoverflow.com/questions/11167628/trees-in-twitter-bootstrap
    $(
        function() {
            $('.tree li').hide();
            $('.tree > ul > li').show();
            $('.tree > ul li').on('click', bindTreeEvent);
        }
    );

}(jQuery);
