$(document).ready(function() {
    var shownClass = 'is-openedAbove';

    var feeds = $('.twitter-feed');
    feeds.each(function(i, e) {
        loadTweets($(this));

        $(this).click(function(e) {
            hideAllShareMenus($(this));
        });

    });

    $('.timeline-ShowMoreButton').click(function() {
        var feed = $(this).closest('.twitter-feed');
        loadTweets(feed);
    });

    function hideAllShareMenus(feed) {
        var shareMenu = feed.find('.timeline-ShareMenu-container');
        shareMenu.hide();
        var container = feed.find('.timeline-ShareMenu');
        container.removeClass(shownClass);
    }

    function loadTweets(feed) {
        var next = feed.data('next');
        if (next === '') {
            showNoMoreTweetsMessage(feed);
            return;
        }

        var url = '/tweets' + next;
        $.ajax({
            method: 'GET',
            url: url
        })
            .done(function(data) {
                $('.twitter-feed-Loading').hide();

                if (!data.hasOwnProperty('statuses') || data.statuses.length === 0) {
                    showNoMoreTweetsMessage(feed);
                    return;
                }

                var btn = feed.find('.timeline-ShowMoreButton');
                btn.show();

                var tweets = data.statuses;

                if (
                    data.hasOwnProperty('search_metadata') &&
                    data.search_metadata.hasOwnProperty('next_results')
                ) {
                    feed.data('next', data.search_metadata.next_results);
                }
                else {
                    feed.data('next', '');
                }

                addTweets(feed, tweets);

                var count = feed.data('twitterlimit') || 0;
                if (tweets.length < count) {
                    hideLoadMoreButton(feed);
                }
            })
            .fail(function() {
                $('.twitter-feed-Loading').hide();
            });
    }

    function showNoMoreTweetsMessage(feed) {
        var msg = feed.find('.timeline-LoadMore-endOfTimelineMessage');
        msg.show().delay(3000).fadeOut();

        hideLoadMoreButton();
    }

    function hideLoadMoreButton(feed) {
        var btn = feed.find('.timeline-ShowMoreButton');
        btn.hide();
    }

    function addTweets(feed, tweets) {
        for (var i = 0; i < tweets.length; i++) {
            addTweet(feed, tweets[i]);
        }
    };

    function addTweet(feed, tweet) {
        var skeletonClass = 'timeline-TweetList-tweet--skeleton';
        var skeleton = feed.find('.' + skeletonClass);
        var copy = skeleton.clone();
        copy.removeClass(skeletonClass);
        copy.addClass('timeline-Tweetlist-tweet');

        var avatar = copy.find('.Avatar')
        avatar.attr('src', tweet.user.profile_image_url_https);

        var link = copy.find('.TweetAuthor-link');
        link.attr('href', 'https://twitter.com/' + tweet.user.screen_name);
        link.attr('aria-label', tweet.user.name + ' (screen name: ' + tweet.user.screen_name + ')');

        var name = copy.find('.TweetAuthor-name');
        name.attr('title', tweet.user.name);
        name.html(tweet.user.name);

        var screenName = copy.find('.TweetAuthor-screenName');
        screenName.attr('title', '@' + tweet.user.screen_name);
        screenName.html('@' + tweet.user.screen_name);

        var text = copy.find('.timeline-Tweet-text');
        text.html(tweet.full_text);

        var metadata = copy.find('.timeline-Tweet-metadata');
        var metadataTimestamp = metadata.find('.timeline-Tweet-timestamp');
        var tweetUrl = 'https://twitter.com/' + tweet.user.screen_name + '/status/' + tweet.id_str;
        metadataTimestamp.attr('href', tweetUrl);
        var metadataTime = metadataTimestamp.find('time');
        var date = new Date(tweet.created_at);
        var options = {year: 'numeric', month: 'short', day: 'numeric' };
        var dateString = date.toLocaleDateString("en-us", options)
        metadataTime.attr('time', tweet.created_at);
        metadataTime.attr('title', 'Time posted: ' + dateString);
        metadataTime.attr('aria-label', 'Posted on ' + dateString);
        metadataTime.html(dateString);

        var actions = copy.find('.timeline-Tweet-actions');
        var heart = actions.find('.TweetAction--heart');
        heart.attr('href', 'https://twitter.com/intent/like?tweet_id=' + tweet.id_str);

        var twitterShare = actions.find('.timeline-ShareMenu-option--twitter');
        var twitterUrl = 'https://twitter.com/intent/retweet?tweet_id=' + tweet.id_str;
        twitterShare.attr('href', twitterUrl);

        var linkedinShare = actions.find('.timeline-ShareMenu-option--linkedin');
        var linkedinUrl = 'https://linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(tweetUrl);
        linkedinShare.attr('href', linkedinUrl);

        var tumblrShare = actions.find('.timeline-ShareMenu-option--tumblr');
        var tumblrUrl = 'https://tumblr.com/widgets/share/tool?canonicalUrl=' + encodeURIComponent(tweetUrl);
        tumblrShare.attr('href', tumblrUrl);

        var shareButton = copy.find('.js-showShareMenu');
        shareButton.click(function(e) {
            e.preventDefault();
            var container = $(this).closest('.timeline-ShareMenu');
            var shareMenu = copy.find('.timeline-ShareMenu-container');
            if (container.hasClass(shownClass)) {
                hideAllShareMenus(feed);
            }
            else {
                hideAllShareMenus(feed);
                shareMenu.show();
                container.addClass(shownClass);
            }
        });

        feed.find('ol.timeline-TweetList').append(copy);

        copy.on('click', function(e) {
            var link = $(this).find('.timeline-Tweet-timestamp');
            var url = link.attr('href');
            window.open(url, '_blank');
        });

        // stop the above event from firing every time a link is clicked
        copy.find('a').on('click', function(e) {
            e.stopPropagation();
        });

        // make all links open in new window
        copy.find('a').each(function(e) {
            $(this).attr('target', '_blank');
        });

        copy.show();
    };
});
