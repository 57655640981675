// product line product description truncate
$(function(){
    $('.product-line .product-desc .content').each(function() {
        $(this).html(
            $.truncate(
                $(this).html(),
                {
                    length: 256,
                    stripTags: false,
                    words: true,
                    noBreaks: false,
                    ellipsis: "..."
                }
            )
        );
    });
});

// product embed rollover
$(function() {
    $(".product-embed").hover(function(e) {
        if (e.type == "mouseenter") {
            topPos = $(this).height() - $(this).find(".product-desc").height();
            $(".product-embed .product-desc").css({"top" : ""});
            $(this).find(".product-desc").css({ "top" : topPos + "px" });
        } else if (e.type == "mouseleave") {
            $(this).find(".product-desc").css({"top" : ""});
        }
    });
});

// product add to cart dropdown
$(document).ready(function() {

    $(".product-addtocart select.dropdown").hide();
    $(".product-addtocart div.dropdown").show();
    // set add to cart member id from value stored in google tag manager
    $('.product-addtocart').submit(function( event ) {
        if ($('#google_datalayer').attr('data-memberid')) {
            $('.product-addtocart input[name="membership"]').val($('#google_datalayer').attr('data-memberid'));
        } else {
            $('.product-addtocart input[name="membership"]').prop("disabled", "disabled");
        }
    });
     

    $(".product-addtocart .dropdown ul li a").click(function() {
        
        // hide selected choice
        $(this).parent().parent().find("li").show();
        $(this).parent().hide();

        // make choice selected, display it as main dropdown button
        $(this).parent().parent().parent()
            .attr(
                "data-selected", 
                $(this).parent().attr("data-value")
            )
            .find("a.btn .btn-text")
                .html($(this).html())
                .attr("title", $(this).html())
        ;

        // enable/disable "add to cart" based on availability
        if (!$(this).attr("data-available")) {
            $(this).parents("form").find("button.btn-cart").prop("disabled", true);
        } else {
            $(this).parents("form").find("button.btn-cart").prop("disabled", false);
        }

        // show/hide pre-order message based on forthcoming attr
        if (!$(this).attr("data-forthcoming")) {
            $(".alert-preorder").addClass("hidden");
        } else if ($(this).attr("data-available")) {    // only display message if product is available
            $(".alert-preorder").removeClass("hidden");
        }

        // change form 'select' field
        $(this).parent().parent().parent().parent().find("select").val($(this).parent().attr("data-value"));

        return true;
    });
});

// donation toggle buttons
$(document).ready(function() {
    var activeButtons = [];
    $(".donation-display-type-2 input[type=radio]").on("click", function() {
        var id = $(this).attr("id");
        var index = activeButtons.indexOf(id);
        var checked = $(this).is(":checked");
        if (checked && index > -1) {
            $(this).removeAttr("checked");
            activeButtons.splice(index, 1);
        } else if ($(this).val() == "_O") {
            $(this).parent().parent().find("input[type=text]").focus();
        }
        $(this).closest(".donation-item").find("input[type=radio]").each(function() {
            var id = $(this).attr("id");
            var index = activeButtons.indexOf(id);
            var checked = $(this).is(":checked");
            if (index == -1 && checked) {
                activeButtons.push($(this).attr("id"));
            } else if (index > -1 && !checked) {
                activeButtons.splice(index, 1);
            }
        });
    });

});

// cart count ajax fetch
$(document).ready(function() {
    var countCountEle = $("[data-cart-count]");
    if (countCountEle.length > 0) {
        $.get("/ajax/cart-count/bookstore", function(data) {
            countCountEle.text(data.count);
        });
    }
});
